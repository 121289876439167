import React from "react";
import { connect } from "react-redux";
import "./personnages.layout.scss";
import Input from "../../shared/input/input.component";
import Select from "../../shared/select/select.component";
import Modal from "../../shared/modal/modal.component";
import Button from "../../shared/button/button.component";
import addIcon from "../../../assets/icons/plus-solid.svg";
import PersonnageDescription from "../../core/components/personnageDescription/personnageDescription.component";

class PersonnagesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItemId: null,
      searchText: "",
      modalOpen: false,
      form: {
        idPersonnageType: { value: null, valid: false },
        idPersonnageRace: { value: null, valid: false },
        name: { value: "", valid: false },
      },
    };
    this.load();
  }
  load = () => {
    const action = {
      type: "list",
      module: "personnage",
      value: null,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);

    const typesAction = {
      type: "list",
      module: "personnageType",
      value: null,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(typesAction);
    const personnageRacesAction = {
      type: "list",
      module: "personnageRace",
      value: null,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(personnageRacesAction);
  };
  onSelect = (item) => {
    this.setState({
      selectedItemId:
        !this.state.selectedItemId ||
        (this.state.selectedItemId && this.state.selectedItemId !== item.id)
          ? item.id
          : null,
    });
  };
  onClose = () => {
    this.setState({ selectedItemId: null });
  };
  onChangeSearch = (value) => {
    this.setState({ searchText: value.value });
  };
  onOpenEdit = (item) => {
    this.setState({ modalOpen: true });
  };
  onCloseEdit = (item) => {
    this.setState({ modalOpen: false });
  };
  onChangeName = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        name: value,
      },
    });
  };
  onSelectType = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        idPersonnageType: {
          ...this.state.form.idPersonnageType,
          value: value.id,
          valid: true,
        },
      },
    });
  };
  onSelectRace = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        idPersonnageRace: {
          ...this.state.form.idPersonnageRace,
          value: value.id,
          valid: true,
        },
      },
    });
  };
  onSave = () => {
    const action = {
      type: "CREATE",
      module: "personnage",
      value: {
        idPersonnageRace: this.state.form.idPersonnageRace.value,
        idPersonnageType: this.state.form.idPersonnageType.value,
        name: this.state.form.name.value,
        description: "",
      },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({
      form: {
        name: { value: "", valid: false },
      },
      modalOpen: false,
    });
  };
  render() {
    const selectedItem = this.props.personnageReducer.personnages.find(
      (personnage) => personnage.id === this.state.selectedItemId
    );
    var personnages = this.props.personnageReducer.personnages.filter(
      (personnage) =>
        personnage.deleted === 0 &&
        personnage.name
          .toUpperCase()
          .search(this.state.searchText.toUpperCase()) !== -1
    );
    return (
      <div className="personnagesLayout">
        <div className="navigation">
          <div className="header">
            <div className="title">Personnages ({personnages.length})</div>
            <div className="actions">
              <div className="action" onClick={() => this.onOpenEdit(null)}>
                <img src={addIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="search">
            <Input
              id="search"
              type="search"
              personnageHolder="Search"
              value={this.state.searchText}
              onChange={this.onChangeSearch}
              canErase
            />
          </div>
          <div className="wrapper">
            {personnages.map((personnage, index) => (
              <div
                key={index}
                className={
                  selectedItem && selectedItem.id === personnage.id
                    ? "item active"
                    : "item"
                }
                onClick={() => this.onSelect(personnage)}
              >
                {personnage.name}
              </div>
            ))}
          </div>
        </div>
        {this.state.selectedItemId ? (
          <div className="content">
            <PersonnageDescription item={selectedItem} onClose={this.onClose} />
          </div>
        ) : (
          <div className="content empty">No selected personnage</div>
        )}
        {this.state.modalOpen && (
          <Modal
            title="New personnage"
            onClose={this.onCloseEdit}
            open={this.state.modalOpen}
          >
            <div className="personnageModal">
              <div className="form">
                <div className="field">
                  <Input
                    id="name"
                    personnageHolder="Name"
                    onChange={this.onChangeName}
                    canErase
                    required
                  />
                </div>
                <div className="field">
                  <Select
                    id="idPersonnageType"
                    labelKey="name"
                    valueKey="id"
                    searchKey="name"
                    placeHolder="Choice type"
                    items={this.props.personnageReducer.personnageTypes.filter(
                      (personnageType) => personnageType.deleted === 0
                    )}
                    onChange={this.onSelectType}
                    required
                  />
                </div>
                <div className="field">
                  <Select
                    id="idPersonnageRace"
                    labelKey="name"
                    valueKey="id"
                    searchKey="name"
                    placeHolder="Choice race"
                    items={this.props.personnageReducer.personnageRaces.filter(
                      (personnageRace) => personnageRace.deleted === 0
                    )}
                    onChange={this.onSelectRace}
                    required
                  />
                </div>
              </div>
              <div className="actions">
                <Button
                  disabled={
                    this.state.form.name.valid &&
                    this.state.form.idPersonnageType.valid &&
                    this.state.form.idPersonnageRace.valid
                      ? false
                      : true
                  }
                  onClick={this.onSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PersonnagesLayout);

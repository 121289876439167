import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./mainNavItem.component.scss";

class MainNavItem extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <NavLink
        to={this.props.item.path}
        className={
          this.props.first
            ? "mainNavItem first"
            : this.props.last
            ? "mainNavItem last"
            : "mainNavItem"
        }
      >
        <img src={this.props.item.icon} alt="" />
      </NavLink>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(MainNavItem);

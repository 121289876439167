import React from "react";
import { connect } from "react-redux";
import "./story.component.scss";

import Modal from "../../../../shared/modal/modal.component";

import closeIcon from "../../../../../assets/icons/chevron-up-solid.svg";
import openIcon from "../../../../../assets/icons/chevron-down-solid.svg";
import editIcon from "../../../../../assets/icons/pen-to-square-solid.svg";
import Input from "../../../../shared/input/input.component";
import Button from "../../../../shared/button/button.component";

class Story extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTabIndex: null,
      editOpen: false,
    };
  }
  onOpenTab = (index) => {
    this.setState({
      openTabIndex: this.state.openTabIndex === index ? null : index,
    });
  };
  onOpenEdit = () => {
    this.setState({ editOpen: true });
  };
  onCloseEdit = () => {
    this.setState({ editOpen: false });
  };
  onChange = (command, value) => {
    const action = {
      type: command,
      module: "playerCharacter",
      value: {
        id: this.props.gameReducer.selectedCharacter.id,
        value: value.value,
      },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  onSave = () => {
    this.setState({ editOpen: false });
  };
  render() {
    var tabs = [
      {
        title: "Personality Traits",
        id: "personalityTraits",
        command: "EDIT_PERSONALITY_TRAITS",
        value: this.props.gameReducer.selectedCharacter.personalityTraits,
      },
      {
        title: "Ideals",
        id: "ideals",
        command: "EDIT_IDEALS",
        value: this.props.gameReducer.selectedCharacter.ideals,
      },
      {
        title: "Bond",
        id: "bond",
        command: "EDIT_BOND",
        value: this.props.gameReducer.selectedCharacter.bond,
      },
      {
        title: "Defaults",
        id: "defaults",
        command: "EDIT_DEFAULTS",
        value: this.props.gameReducer.selectedCharacter.defaults,
      },
      {
        title: "Story",
        id: "story",
        command: "EDIT_STORY",
        value: this.props.gameReducer.selectedCharacter.story,
      },
    ];
    return (
      <div className="story">
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              className={this.state.openTabIndex === index ? "active" : ""}
            >
              <div className="titleBox">
                <div
                  className="title"
                  onClick={() => {
                    this.onOpenTab(index);
                  }}
                >
                  {tab.title}
                </div>
                <div className="actions">
                  {this.state.openTabIndex === index && (
                    <div className="action" onClick={this.onOpenEdit}>
                      <img src={editIcon} alt="" />
                    </div>
                  )}

                  <div
                    className="action"
                    onClick={() => {
                      this.onOpenTab(index);
                    }}
                  >
                    <img
                      src={
                        this.state.openTabIndex === index ? closeIcon : openIcon
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="value">{tab.value}</div>
              {this.state.openTabIndex === index && this.state.editOpen && (
                <Modal title={tab.title} onClose={this.onCloseEdit}>
                  <Input
                    id={tab.id}
                    type="textarea"
                    value={tab.value}
                    onChange={(value) => {
                      this.onChange(tab.command, value);
                    }}
                  />
                  {/* <Button onClick={this.onSave}>Save</Button> */}
                </Modal>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(Story);

import React from "react";
import { connect } from "react-redux";
import "./gameSettings.layout.scss";
import Button from "../../shared/button/button.component";

class GameSettingsLayout extends React.Component {
  onUnselectGame = () => {
    console.log("OK");
    const action = {
      type: "UNSELECT_GAME",
      module: "game",
      value: this.props.gameReducer.selectedGame,
      socket: {
        send: true,
      },
    };

    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="gameSettings">
        <div className="contentBox">
          <Button onClick={this.onUnselectGame}>Change game</Button>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(GameSettingsLayout);

import React from "react";
import { connect } from "react-redux";
import "./map.layout.scss";
import MapSelector from "../../core/components/mapSelector/mapSelector.component";

class MapLayout extends React.Component {
  render() {
    this.props.gameReducer.selectedGame.currentMap &&
      console.log(this.props.gameReducer.selectedGame.currentMap);
    return (
      <>
        <div className="mapLayout">
          {this.props.gameReducer.selectedGame &&
          this.props.gameReducer.selectedGame.currentMap ? (
            <img
              className={"image"}
              src={this.props.gameReducer.selectedGame.currentMap.url}
              alt=""
            />
          ) : (
            "No map selected"
          )}
        </div>
        <div className="mapSelectorBox">
          <div className="mapSelectorTitle">Maps</div>
          <MapSelector />
        </div>
      </>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(MapLayout);

import React from "react";
import { connect } from "react-redux";
import "./personnageDescription.component.scss";
import Input from "../../../shared/input/input.component";
import Select from "../../../shared/select/select.component";
import closeIcon from "../../../../assets/icons/xmark-solid.svg";
import deleteIcon from "../../../../assets/icons/trash-solid.svg";
import editIcon from "../../../../assets/icons/pen-to-square-solid.svg";
import saveIcon from "../../../../assets/icons/check-solid.svg";
class PersonnageDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,

      onEditName: false,
      name: this.props.item.name ? this.props.item.name : "",

      onEditType: false,
      type: this.props.item.type,

      onEditRace: false,
      race: this.props.item.race,

      description: this.props.item.description
        ? this.props.item.description
        : "",
    };
  }
  onDelete = () => {
    this.setState({ confirmDelete: true });
  };
  onConfirmDelete = () => {
    const action = {
      type: "DELETE",
      module: "personnage",
      value: this.props.item,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ confirmDelete: false });
    this.props.onClose && this.props.onClose();
  };

  onEditName = () => {
    this.setState({ onEditName: true });
  };
  onChangeName = (value) => {
    this.setState({ name: value.value });
  };
  onSaveName = () => {
    const action = {
      type: "EDIT_NAME",
      module: "personnage",
      value: { id: this.props.item.id, value: this.state.name },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditName: false });
  };
  onEditType = () => {
    this.setState({ onEditType: true });
  };
  onChangeType = (value) => {
    console.log(value);
    this.setState({ type: value });
  };
  onSaveType = () => {
    const action = {
      type: "EDIT_TYPE",
      module: "personnage",
      value: { id: this.props.item.id, value: this.state.type },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditType: false });
  };
  onEditRace = () => {
    this.setState({ onEditRace: true });
  };
  onChangeRace = (value) => {
    console.log(value);
    this.setState({ race: value });
  };
  onSaveRace = () => {
    const action = {
      type: "EDIT_RACE",
      module: "personnage",
      value: { id: this.props.item.id, value: this.state.race },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditRace: false });
  };
  onEditDescription = () => {
    this.setState({ onEditDescription: true });
  };
  onChangeDescription = (value) => {
    this.setState({ description: value.value });
  };
  onSaveDescription = () => {
    const action = {
      type: "EDIT_DESCRIPTION",
      module: "personnage",
      value: { id: this.props.item.id, value: this.state.description },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditDescription: false });
  };

  render() {
    return (
      <div className="personnageDescription">
        <div className="header">
          <div className="title">{this.props.item.name}</div>
          <div className="actions">
            <div
              className="action"
              onClick={
                this.state.confirmDelete ? this.onConfirmDelete : this.onDelete
              }
            >
              <img
                className={
                  this.state.confirmDelete ? "deleteIcon confirm" : "deleteIcon"
                }
                src={deleteIcon}
                alt=""
              />
            </div>
            <div
              className="action"
              onClick={this.props.onClose && this.props.onClose}
            >
              <img src={closeIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row">
            <div className="mainInformations">
              <div className="box">
                <div className="label">
                  Name
                  <img
                    src={this.state.onEditName ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditName ? this.onSaveName : this.onEditName
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditName ? (
                    <Input
                      id="name"
                      personnageHolder="Name"
                      value={this.state.name}
                      onChange={this.onChangeName}
                      required
                      canErase
                      canCancel
                    />
                  ) : (
                    this.props.item.name
                  )}
                </div>
              </div>

              <div className="box">
                <div className="label">
                  Type
                  <img
                    src={this.state.onEditType ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditType ? this.onSaveType : this.onEditType
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditType ? (
                    <Select
                      labelKey="name"
                      valueKey="id"
                      searchKey="name"
                      placeHolder="Choice type"
                      items={this.props.personnageReducer.personnageTypes.filter(
                        (personnageType) => personnageType.deleted === 0
                      )}
                      onChange={this.onChangeType}
                    />
                  ) : (
                    this.props.item.type.name
                  )}
                </div>
              </div>

              <div className="box">
                <div className="label">
                  Race
                  <img
                    src={this.state.onEditRace ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditRace ? this.onSaveRace : this.onEditRace
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditRace ? (
                    <Select
                      labelKey="name"
                      valueKey="id"
                      searchKey="name"
                      placeHolder="Choice race"
                      items={this.props.personnageReducer.personnageRaces.filter(
                        (enemy) => enemy.deleted === 0
                      )}
                      onChange={this.onChangeRace}
                    />
                  ) : (
                    this.props.item.race.name
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="description">
            <div className="label">
              Description
              <img
                src={this.state.onEditDescription ? saveIcon : editIcon}
                alt=""
                onClick={
                  this.state.onEditDescription
                    ? this.onSaveDescription
                    : this.onEditDescription
                }
              />
            </div>
            <div className="value">
              {this.state.onEditDescription ? (
                <Input
                  id="description"
                  type="textarea"
                  value={this.state.description}
                  onChange={this.onChangeDescription}
                />
              ) : (
                this.props.item.description
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PersonnageDescription);

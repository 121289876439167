import React from "react";
import { connect } from "react-redux";
import "./tryVsDead.component.scss";

import skullIcon from "../../../../assets/icons/skull-solid.svg";

class TryVsDead extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = (id, index) => {
    var value = JSON.parse(this.props.item.tryVsDead);
    if (value[index] === null) {
      value[index] = true;
    } else if (value[index] === true) {
      value[index] = false;
    } else {
      value[index] = null;
    }
    const action = {
      type: "EDIT_TRYVSDEAD",
      module: "playerCharacter",
      value: { id: id, value: JSON.stringify(value) },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="tryToDead">
        <div className="title">Try vs dead</div>
        <div className="wrapper">
          {JSON.parse(this.props.item.tryVsDead).map((item, index) => (
            <div
              key={index}
              className={
                item === true
                  ? "item win"
                  : item === false
                  ? "item lose"
                  : "item"
              }
              onClick={() => {
                this.onChange(this.props.item.id, index);
              }}
            >
              <img src={skullIcon} alt="" />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(TryVsDead);

import React from "react";
import { connect } from "react-redux";
import "./gameClock.component.scss";

import Button from "../../../shared/button/button.component";
import Input from "../../../shared/input/input.component";

import settingsIcon from "../../../../assets/icons/gear-solid.svg";
import playIcon from "../../../../assets/icons/play-solid.svg";
import pauseIcon from "../../../../assets/icons/pause-solid.svg";
import plusIcon from "../../../../assets/icons/plus-solid.svg";

class GameClock extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      onPlay: false,
      settingsOpen: false,
      addTimeOpen: false,
      timeRatio: 1,
    };
  }
  onPlay = () => {
    this.setState({ onPlay: true, settingsOpen: false, addTimeOpen: false });
    this.timer = setInterval(() => {
      const action = {
        type: "EDIT_DURATION",
        module: "game",
        value: {
          id: this.props.gameReducer.selectedGame.id,
          value:
            this.props.gameReducer.selectedGame.duration +
            this.state.timeRatio * 60,
        },
        socket: {
          send: true,
        },
      };
      this.props.dispatch(action);
    }, 1000);
  };
  onPause = () => {
    this.setState({ onPlay: false });
    clearInterval(this.timer);
  };
  onToggleSettings = () => {
    this.setState({ settingsOpen: !this.state.settingsOpen });
  };
  onToggleAddTime = () => {
    this.setState({ addTimeOpen: !this.state.addTimeOpen });
  };
  onAddTime = (seconds) => {
    const action = {
      type: "EDIT_DURATION",
      module: "game",
      value: {
        id: this.props.gameReducer.selectedGame.id,
        value: this.props.gameReducer.selectedGame.duration + seconds,
      },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  onChangeTimeRatio = (value) => {
    this.setState({ timeRatio: value.value });
  };
  render() {
    const duration = renderDuration(
      this.props.gameReducer.selectedGame.duration
    );
    return (
      <div className="gameClock">
        <div className="content">
          <div>Day {duration.days}</div>
          <div>
            {pad2(duration.hours)}h{pad2(duration.minutes)}
          </div>
        </div>
        <div className="actions">
          {!this.state.onPlay && (
            <>
              <div className="action" onClick={this.onToggleSettings}>
                <img src={settingsIcon} alt="" />
              </div>
              <div className="action" onClick={this.onToggleAddTime}>
                <img src={plusIcon} alt="" />
              </div>
            </>
          )}

          <div
            className="action"
            onClick={this.state.onPlay ? this.onPause : this.onPlay}
          >
            {this.state.onPlay ? (
              <img src={pauseIcon} alt="" />
            ) : (
              <img src={playIcon} alt="" />
            )}
          </div>
        </div>
        {this.state.addTimeOpen && (
          <div className="addTime">
            <div>
              <Button onClick={() => this.onAddTime(-1 * 60)}>-1 min</Button>
              <Button onClick={() => this.onAddTime(1 * 60)}>+1 min</Button>
            </div>
            <div>
              <Button onClick={() => this.onAddTime(-15 * 60)}>-15 min</Button>
              <Button onClick={() => this.onAddTime(15 * 60)}>+15 min</Button>
            </div>
            <div>
              <Button onClick={() => this.onAddTime(-60 * 60)}>-1 hour</Button>
              <Button onClick={() => this.onAddTime(60 * 60)}>+1 hour</Button>
            </div>
            <div>
              <Button onClick={() => this.onAddTime(-1440 * 60)}>-1 day</Button>
              <Button onClick={() => this.onAddTime(1440 * 60)}>+1 day</Button>
            </div>
          </div>
        )}
        {this.state.settingsOpen && (
          <div className="settings">
            <Input
              id="timeRatio"
              label="timeRatio"
              type="number"
              placeHolder="game min/irl sec"
              value={this.state.timeRatio}
              onChange={this.onChangeTimeRatio}
            />
          </div>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
function renderDuration(seconds) {
  if (typeof seconds !== "number" || isNaN(seconds) || seconds < 0) {
    return { error: "Invalid input" };
  }

  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: remainingSeconds,
  };
}

function pad2(value) {
  return value > 9 ? value : "0" + value;
}
export default connect(StateToProps, DispatchToProps)(GameClock);

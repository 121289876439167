import React from "react";
import { connect } from "react-redux";
import "./playerLife.component.scss";

import skullIcon from "../../../../assets/icons/skull-solid.svg";
import lockIcon from "../../../../assets/icons/lock-solid.svg";
import lockOpenIcon from "../../../../assets/icons/lock-open-solid.svg";

class PlayerLife extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = (id, value) => {
    const action = {
      type: "EDIT_LIFE",
      module: "playerCharacter",
      value: { id: id, value: value },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  onToggleCanEditLife = (id) => {
    const action = {
      type: "EDIT_CAN_EDIT_LIFE",
      module: "playerCharacter",
      value: { id: id, value: this.props.item.canEditLife ? 0 : 1 },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="playerLife">
        <div className="header">
          <div
            className={this.props.item.dead ? "dead isDead" : "dead"}
            onClick={() => {
              !this.props.item.dead && this.onChange(this.props.item.id, 0);
            }}
          >
            <img src={skullIcon} alt="" />
          </div>
          <div className="title">Life</div>
          <div className="actions">
            <div
              className={
                this.props.item.canEditLife ? "action" : "action locked"
              }
              onClick={() => {
                this.onToggleCanEditLife(this.props.item.id);
              }}
            >
              <img
                src={this.props.item.canEditLife ? lockOpenIcon : lockIcon}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="actions">
          <div
            className="action"
            onClick={() => {
              this.onChange(this.props.item.id, this.props.item.life - 1);
            }}
          >
            -
          </div>
          <div className="life">
            {this.props.item.life}/{this.props.item.maxLife}
          </div>
          <div
            className="action"
            onClick={() => {
              this.onChange(this.props.item.id, this.props.item.life + 1);
            }}
          >
            +
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayerLife);

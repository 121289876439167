import React from "react";
import { connect } from "react-redux";
import "./raceInfo.component.scss";

class RaceInfo extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = (id, value) => {
    const action = {
      type: "EDIT_RACE",
      module: "playerCharacter",
      value: { id: id, value: value },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="raceInfo">
        <div className="title">
          {this.props.item.race ? this.props.item.race.name : "-"}
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(RaceInfo);

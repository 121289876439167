import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./playerItem.component.scss";
import PlayerLife from "../../../../core/components/playerLife/playerLife.component";
import PlayerMaxLife from "../../../../core/components/playerMaxLife/playerMaxLife.component";
import TryVsDead from "../../../../core/components/tryVsDead/tryVsDead.component";
import RaceInfo from "../../../../core/components/raceInfo/raceInfo.component";
import ClassInfo from "../../../../core/components/classInfo/classInfo.component";
import PlayerCharacteristics from "../../../../core/components/playerCharacteristics/playerCharacteristics.component";
import openIcon from "../../../../../assets/icons/gear-solid.svg";
import PlayerLevel from "../../../../core/components/playerLevel/playerLevel.component";
class PlayerItem extends React.Component {
  constructor(props) {
    super(props);
  }
  onOpen = (item) => {
    const action = {
      type: "SELECT_CHARACTER",
      module: "game",
      value: item,
      socket: {
        send: false,
      },
    };

    this.props.dispatch(action);
  };
  render() {
    const currentCharacter = this.props.item.selectedCharacterId
      ? this.props.item.characters.find(
          (character) => character.id === this.props.item.selectedCharacterId
        )
      : null;
    return (
      <div className="playerItem">
        <div className="nameBox">
          <div></div>
          <div>
            {currentCharacter
              ? currentCharacter.name
              : this.props.item.username}
          </div>
          <div>
            {this.props.item.selectedCharacterId && (
              <NavLink
                className="openIconBox"
                to="/player"
                onClick={() => {
                  this.onOpen(currentCharacter);
                }}
              >
                <img className="openIcon" src={openIcon} alt="" />
              </NavLink>
            )}
          </div>
        </div>

        {!currentCharacter && <div className="statusBox">Select character</div>}
        {currentCharacter && (
          <div className="playerLifeBox">
            <div className="row">
              <RaceInfo item={currentCharacter} />
              <ClassInfo item={currentCharacter} />
            </div>
            <div className="row">
              <PlayerLevel item={currentCharacter} />
              <PlayerMaxLife item={currentCharacter} />
            </div>
            <div className="row">
              <PlayerLife item={currentCharacter} />
            </div>
            {currentCharacter.dead === 1 && (
              <div className="row">
                <TryVsDead item={currentCharacter} />
              </div>
            )}
            <div className="row">
              <PlayerCharacteristics item={currentCharacter} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayerItem);

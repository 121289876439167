import React from "react";
import { connect } from "react-redux";
import "./enemySelector.component.scss";

import Select from "../../../../../shared/select/select.component";
import Input from "../../../../../shared/input/input.component";
import Button from "../../../../../shared/button/button.component";

import addIcon from "../../../../../../assets/icons/plus-solid.svg";

class EnemySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEnemy: null,
      number: 1,
    };
    const action = {
      type: "list",
      module: "enemy",
      value: null,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  }
  onChangeNumber = (value) => {
    console.log(value);
    // this.setState({selectedEnemy: value})
  };
  onChangeEnemy = (value) => {
    // console.log(value);
    this.setState({ selectedEnemy: value });
  };
  render() {
    return (
      <div className="enemySelector">
        <div className="content">
          <Input
            id="number"
            type="number"
            min="1"
            max="10"
            placeHolder="Nb"
            value={this.state.number}
            onChange={this.onChangeNumber}
          />
          <Select
            labelKey="name"
            valueKey="id"
            searchKey="name"
            placeHolder="Choice enemy"
            items={this.props.enemyReducer.enemies.filter(
              (enemy) => enemy.deleted === 0
            )}
            openUp
            onChange={this.onChangeEnemy}
          />
          <Button
            disabled={
              this.state.selectedEnemy && this.state.number ? false : true
            }
          >
            <img className="addIcon" src={addIcon} alt="" />
          </Button>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(EnemySelector);

const initialState = {
  alignments: [],
};
function alignmentReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "UPDATE_ALIGNMENTS":
      nextState = {
        ...state,
        alignments: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default alignmentReducer;

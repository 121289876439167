const initialState = {
  selectedMap: null,
  maps: [],
};
function mapReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "UPDATE_GAME_MAPS":
      const selectedMap = state.selectedMap
        ? action.value.find((map) => map.id === state.selectedMap.id)
        : state.selectedMap;

      nextState = {
        ...state,
        maps: action.value,
        selectedMap: selectedMap,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default mapReducer;

import React from "react";
import { connect } from "react-redux";
import "./players.layout.scss";
import PlayerItem from "./components/playerItem/playerItem.component";

class PlayersLayout extends React.Component {
  constructor(props) {
    super(props);
  }
  onOpen = (item) => {
    const action = {
      type: "SELECT_CHARACTER",
      module: "game",
      value: item,
      socket: {
        send: false,
      },
    };

    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="playersLayout">
        {this.props.gameReducer.selectedGame &&
        this.props.gameReducer.selectedGame.players.length > 0 ? (
          <div className="wrapper">
            {this.props.gameReducer.selectedGame.players.map(
              (player, index) => (
                <div className="item" key={index}>
                  <PlayerItem item={player} />
                </div>
              )
            )}
            {this.props.gameReducer.selectedGame.players.length < 5 &&
              [
                ...Array(
                  5 - this.props.gameReducer.selectedGame.players.length
                ),
              ].map((x, index) => (
                <div className="item empty" key={index}>
                  <div>No player</div>
                  <div>(select in playerCharacters)</div>
                </div>
              ))}
          </div>
        ) : (
          <div className="empty">No player connected in this game</div>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayersLayout);

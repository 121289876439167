import React from "react";
import { connect } from "react-redux";
import "./playerCharacteristicItem.component.scss";

class PlayerCharacteristicItem extends React.Component {
  onChange = (value) => {
    const action = {
      type: "EDIT_CHARACTERISTIC",
      module: "playerCharacter",
      value: { id: this.props.id, key: this.props.item.key, value: value },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  render() {
    var bonus = Math.floor((this.props.value - 10) / 2);
    return (
      <div className="playerCharacteristicItem" title={this.props.item.key}>
        <div className="iconBox">
          <img src={this.props.item.icon} alt="" />
        </div>
        <div className="actionsBox">
          <div
            className="action"
            onClick={() => {
              this.onChange(this.props.value - 1);
            }}
          >
            -
          </div>
          <div className="value">{this.props.value}</div>
          <div
            className="action"
            onClick={() => {
              this.onChange(this.props.value + 1);
            }}
          >
            +
          </div>
        </div>
        <div className="bonusBox">
          <div className="value">
            {bonus >= 0 && "+"}
            {bonus}
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayerCharacteristicItem);

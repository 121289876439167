import React from "react";
import { connect } from "react-redux";
import "./enemyDescription.component.scss";
import EnemyCharacteristics from "../enemyCharacteristics/enemyCharacteristics.component";
import Input from "../../../shared/input/input.component";
import Select from "../../../shared/select/select.component";
import closeIcon from "../../../../assets/icons/xmark-solid.svg";
import deleteIcon from "../../../../assets/icons/trash-solid.svg";
import editIcon from "../../../../assets/icons/pen-to-square-solid.svg";
import saveIcon from "../../../../assets/icons/check-solid.svg";
class EnemyDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDelete: false,

      onEditName: false,
      name: this.props.item.name,

      onEditType: false,
      type: this.props.item.race,

      onEditAlignment: false,
      alignment: this.props.item.alignment,

      onEditArmorClass: false,
      armorClass: this.props.item.armorClass,

      onEditDarkvision: false,
      darkvision: this.props.item.darkvision,

      onEditLevel: false,
      level: this.props.item.level,

      onEditLife: false,
      life: this.props.item.life,

      onEditSpeed: false,
      speed: this.props.item.speed,

      onEditDescription: false,
      description: this.props.item.description
        ? this.props.item.description
        : "",
    };
  }
  onDelete = () => {
    this.setState({ confirmDelete: true });
  };
  onConfirmDelete = () => {
    const action = {
      type: "DELETE",
      module: "enemy",
      value: this.props.item,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ confirmDelete: false });
    this.props.onClose && this.props.onClose();
  };

  onEditName = () => {
    this.setState({ onEditName: true });
  };
  onChangeName = (value) => {
    this.setState({ name: value.value });
  };
  onSaveName = () => {
    const action = {
      type: "EDIT_NAME",
      module: "enemy",
      value: { id: this.props.item.id, value: this.state.name },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditName: false });
  };

  onEditType = () => {
    this.setState({ onEditType: true });
  };
  onChangeType = (value) => {
    console.log(value);
    this.setState({ type: value });
  };
  onSaveType = () => {
    const action = {
      type: "EDIT_TYPE",
      module: "enemy",
      value: { id: this.props.item.id, value: this.state.type },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditType: false });
  };

  onEditAlignment = () => {
    this.setState({ onEditAlignment: true });
  };
  onChangeAlignment = (value) => {
    this.setState({ alignment: value });
  };
  onSaveAlignment = () => {
    const action = {
      type: "EDIT_ALIGNMENT",
      module: "enemy",
      value: { id: this.props.item.id, value: this.state.alignment },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditAlignment: false });
  };

  onEditArmorClass = () => {
    this.setState({ onEditArmorClass: true });
  };
  onChangeArmorClass = (value) => {
    this.setState({ armorClass: value.value });
  };
  onSaveArmorClass = () => {
    const action = {
      type: "EDIT_ARMOR_CLASS",
      module: "enemy",
      value: { id: this.props.item.id, value: this.state.armorClass },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditArmorClass: false });
  };

  onEditDarkvision = () => {
    this.setState({ onEditDarkvision: true });
  };
  onChangeDarkvision = (value) => {
    this.setState({ darkvision: value.value });
  };
  onSaveDarkvision = () => {
    const action = {
      type: "EDIT_DARKVISION",
      module: "enemy",
      value: { id: this.props.item.id, value: this.state.darkvision },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditDarkvision: false });
  };

  onEditLevel = () => {
    this.setState({ onEditLevel: true });
  };
  onChangeLevel = (value) => {
    this.setState({ level: value.value });
  };
  onSaveLevel = () => {
    const action = {
      type: "EDIT_LEVEL",
      module: "enemy",
      value: { id: this.props.item.id, value: this.state.level },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditLevel: false });
  };

  onEditLife = () => {
    this.setState({ onEditLife: true });
  };
  onChangeLife = (value) => {
    this.setState({ life: value.value });
  };
  onSaveLife = () => {
    const action = {
      type: "EDIT_LIFE",
      module: "enemy",
      value: { id: this.props.item.id, value: this.state.life },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditLife: false });
  };

  onEditSpeed = () => {
    this.setState({ onEditSpeed: true });
  };
  onChangeSpeed = (value) => {
    this.setState({ speed: value.value });
  };
  onSaveSpeed = () => {
    const action = {
      type: "EDIT_SPEED",
      module: "enemy",
      value: { id: this.props.item.id, value: this.state.speed },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditSpeed: false });
  };

  onEditDescription = () => {
    this.setState({ onEditDescription: true });
  };
  onChangeDescription = (value) => {
    this.setState({ description: value.value });
  };
  onSaveDescription = () => {
    const action = {
      type: "EDIT_DESCRIPTION",
      module: "enemy",
      value: { id: this.props.item.id, value: this.state.description },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({ onEditDescription: false });
  };

  render() {
    return (
      <div className="enemyDescription">
        <div className="header">
          <div className="title">{this.props.item.name}</div>
          <div className="actions">
            <div
              className="action"
              onClick={
                this.state.confirmDelete ? this.onConfirmDelete : this.onDelete
              }
            >
              <img
                className={
                  this.state.confirmDelete ? "deleteIcon confirm" : "deleteIcon"
                }
                src={deleteIcon}
                alt=""
              />
            </div>
            <div
              className="action"
              onClick={this.props.onClose && this.props.onClose}
            >
              <img src={closeIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row">
            <div className="mainInformations">
              <div className="box">
                <div className="label">
                  Name
                  <img
                    src={this.state.onEditName ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditName ? this.onSaveName : this.onEditName
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditName ? (
                    <Input
                      id="name"
                      placeHolder="Name"
                      value={this.state.name}
                      onChange={this.onChangeName}
                      required
                      canErase
                      canCancel
                    />
                  ) : (
                    this.props.item.name
                  )}
                </div>
              </div>
              <div className="box">
                <div className="label">
                  Type
                  <img
                    src={this.state.onEditType ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditType ? this.onSaveType : this.onEditType
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditType ? (
                    <Select
                      labelKey="name"
                      valueKey="id"
                      searchKey="name"
                      placeHolder="Choice type"
                      items={this.props.enemyReducer.enemyRaces.filter(
                        (enemy) => enemy.deleted === 0
                      )}
                      onChange={this.onChangeType}
                    />
                  ) : (
                    this.props.item.race.name
                  )}
                </div>
              </div>
              <div className="box">
                <div className="label">
                  Alignment
                  <img
                    src={this.state.onEditAlignment ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditAlignment
                        ? this.onSaveAlignment
                        : this.onEditAlignment
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditAlignment ? (
                    <Select
                      labelKey="name"
                      valueKey="id"
                      searchKey="name"
                      placeHolder="Choice alignment"
                      items={this.props.alignmentReducer.alignments.filter(
                        (alignment) => alignment.deleted === 0
                      )}
                      onChange={this.onChangeAlignment}
                    />
                  ) : (
                    this.props.item.alignment.name
                  )}
                </div>
              </div>
              <div className="box">
                <div className="label">
                  Armor class
                  <img
                    src={this.state.onEditArmorClass ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditArmorClass
                        ? this.onSaveArmorClass
                        : this.onEditArmorClass
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditArmorClass ? (
                    <Input
                      id="armorClass"
                      type="number"
                      min="1"
                      step="1"
                      placeHolder="Armor class"
                      value={this.state.armorClass}
                      onChange={this.onChangeArmorClass}
                      required
                      canErase
                      canCancel
                    />
                  ) : (
                    this.props.item.armorClass
                  )}
                </div>
              </div>
              <div className="box">
                <div className="label">
                  Dark vision
                  <img
                    src={this.state.onEditDarkvision ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditDarkvision
                        ? this.onSaveDarkvision
                        : this.onEditDarkvision
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditDarkvision ? (
                    <Input
                      id="darkvision"
                      placeHolder="Darkvision"
                      value={this.state.darkvision}
                      onChange={this.onChangeDarkvision}
                      required
                      canErase
                      canCancel
                    />
                  ) : (
                    this.props.item.darkvision
                  )}
                </div>
              </div>

              <div className="box">
                <div className="label">
                  Level
                  <img
                    src={this.state.onEditLevel ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditLevel
                        ? this.onSaveLevel
                        : this.onEditLevel
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditLevel ? (
                    <Input
                      id="level"
                      type="number"
                      min="1"
                      step="1"
                      placeHolder="Level"
                      value={this.state.level}
                      onChange={this.onChangeLevel}
                      required
                      canErase
                      canCancel
                    />
                  ) : (
                    this.props.item.level
                  )}
                </div>
              </div>
              <div className="box">
                <div className="label">
                  Life
                  <img
                    src={this.state.onEditLife ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditLife ? this.onSaveLife : this.onEditLife
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditLife ? (
                    <Input
                      id="life"
                      type="number"
                      min="1"
                      step="1"
                      placeHolder="Life"
                      value={this.state.life}
                      onChange={this.onChangeLife}
                      required
                      canErase
                      canCancel
                    />
                  ) : (
                    this.props.item.life
                  )}
                </div>
              </div>
              <div className="box">
                <div className="label">
                  Speed
                  <img
                    src={this.state.onEditSpeed ? saveIcon : editIcon}
                    alt=""
                    onClick={
                      this.state.onEditSpeed
                        ? this.onSaveSpeed
                        : this.onEditSpeed
                    }
                  />
                </div>
                <div className="value">
                  {this.state.onEditSpeed ? (
                    <Input
                      id="speed"
                      placeHolder="Speed"
                      value={this.state.speed}
                      onChange={this.onChangeSpeed}
                      required
                      canErase
                      canCancel
                    />
                  ) : (
                    this.props.item.speed
                  )}
                </div>
              </div>
            </div>
            <div className="characteristics">
              <EnemyCharacteristics item={this.props.item} />
            </div>
          </div>

          <div className="description">
            <div className="label">
              Description
              <img
                src={this.state.onEditDescription ? saveIcon : editIcon}
                alt=""
                onClick={
                  this.state.onEditDescription
                    ? this.onSaveDescription
                    : this.onEditDescription
                }
              />
            </div>
            <div className="value">
              {this.state.onEditDescription ? (
                <Input
                  id="description"
                  type="textarea"
                  value={this.state.description}
                  onChange={this.onChangeDescription}
                />
              ) : (
                this.props.item.description
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(EnemyDescription);

import React from "react";
import { connect } from "react-redux";
import "./player.layout.scss";
import PlayerItem from "./components/playerItem/playerItem.component";
import Inventory from "./components/inventory/inventory.component";
import Story from "./components/story/story.component";

import inventoryIcon from "../../../assets/icons/treasure-chest-solid.svg";
import StoryIcon from "../../../assets/icons/scroll-solid.svg";
import lockIcon from "../../../assets/icons/lock-solid.svg";
import lockOpenIcon from "../../../assets/icons/lock-open-solid.svg";

class PlayerLayout extends React.Component {
  onToggleCanEditStory = () => {
    const action = {
      type: "EDIT_CAN_EDIT_STORY",
      module: "playerCharacter",
      value: {
        id: this.props.gameReducer.selectedCharacter.id,
        value: this.props.gameReducer.selectedCharacter.canEditStory ? 0 : 1,
      },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="playerLayout">
        {this.props.gameReducer.selectedCharacter ? (
          <div className="mainContent">
            <div className="playerItemBox">
              <PlayerItem item={this.props.gameReducer.selectedCharacter} />
            </div>
            <div className="playerItemContent">
              <div className="inventoryBox">
                <div className="header">
                  <div className="iconBox">
                    <img className="icon" src={inventoryIcon} alt="" />
                  </div>
                  <div className="labelBox">Inventory</div>
                </div>
                <div className="content">
                  <Inventory />
                </div>
              </div>
              <div className="storyBox">
                <div className="header">
                  <div className="iconBox">
                    <img className="icon" src={StoryIcon} alt="" />
                  </div>
                  <div className="labelBox">Story</div>
                  <div className="actionsBox">
                    <div
                      className="iconBox"
                      onClick={this.onToggleCanEditStory}
                    >
                      <img
                        className="icon"
                        src={
                          this.props.gameReducer.selectedCharacter.canEditStory
                            ? lockOpenIcon
                            : lockIcon
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="content">
                  <Story />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayerLayout);

import React from "react";
import { connect } from "react-redux";
import "./battleManager.component.scss";

import Button from "../../../shared/button/button.component";
import PlayerCharacterSelector from "./components/playerCharacterSelector/playerCharacterSelector.component";
import EnemySelector from "./components/enemySelector/enemySelector.component";

import editIcon from "../../../../assets/icons/gear-solid.svg";
import saveIcon from "../../../../assets/icons/check-solid.svg";

class BattleManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onEdit: true,
    };
  }
  onEdit = () => {
    this.setState({ onEdit: true });
  };
  onSave = () => {
    this.setState({ onEdit: false });
  };
  render() {
    return (
      <div
        className={this.state.onEdit ? "battleManager onEdit" : "battleManager"}
      >
        <div className="header">
          <div className="title">Battle Manager</div>
          <div className="actions">
            <div
              className="action"
              onClick={this.state.onEdit ? this.onSave : this.onEdit}
            >
              <img src={this.state.onEdit ? saveIcon : editIcon} />
            </div>
          </div>
        </div>
        <div className="content">aa</div>

        <div className="footer">
          {!this.state.onEdit && (
            <>
              <Button>Prevuis</Button>
              <Button>Next</Button>
            </>
          )}
          {this.state.onEdit && (
            <div className="playerCharacterSelectorBox">
              <PlayerCharacterSelector />
            </div>
          )}
          {this.state.onEdit && (
            <div className="enemySelectorBox">
              <EnemySelector />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(BattleManager);

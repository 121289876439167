import React from "react";
import { connect } from "react-redux";
import "./items.layout.scss";

class ItemsLayout extends React.Component {
  render() {
    return <div className="itemsLayout">items</div>;
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(ItemsLayout);

import React from "react";
import "./modal.component.scss";

import closeIcon from "../../../assets/icons/xmark-solid.svg";

class Modal extends React.Component {
  render() {
    return (
      <div className="modal">
        <div className="mainBox">
          <div className="header">
            <div className="title">{this.props.title}</div>
            <div className="actions">
              <div
                className="action"
                onClick={this.props.onClose && this.props.onClose}
              >
                <img src={closeIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
export default Modal;

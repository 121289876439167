import { createStore, applyMiddleware } from "redux";
import { io } from "socket.io-client";
import AppService from "../services/app.service";

import { wsMiddleWare } from "./wsMiddleWare";
import rootReducer from "./reducers/root.reducer";

var socket = io(
  AppService.getBackendUrl() + ":" + AppService.getBackendPort(),
  {
    query: { type: "master" },
  }
);

socket.on("connect", () => {
  AppService.getUid((uuid) => {
    socket.emit("event", { type: "connect", module: "game", value: uuid });
  });
});
socket.on("connect_error", (error) => {
  console.error("Error");
  console.error(error);
  store.dispatch({ type: "disconnect_profil" });
  store.dispatch({ type: "disconnect" });
  setTimeout(() => {
    socket.connect();
  }, 1000);
});
socket.on("disconnect", (reason) => {
  store.dispatch({ type: "disconnect" });
  store.dispatch({ type: "UNSELECT_GAME", value: null });
  setTimeout(() => {
    socket.connect();
  }, 1000);
});
socket.on("message", (data) => {
  if (data.type !== undefined) {
    store.dispatch(data);
  } else {
    data.type = data.callback;
    store.dispatch(data);
  }
});
var store = createStore(
  rootReducer,
  { sessionReducer: { connected: false, loged: false } },
  applyMiddleware(wsMiddleWare(socket))
);
export default store;

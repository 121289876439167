const initialState = {
  enemies: [],
  enemyRaces: [],
};
function enemyReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "UPDATE_ENEMIES":
      nextState = {
        ...state,
        enemies: action.value,
      };
      return nextState || state;
    case "UPDATE_ENEMY_RACES":
      nextState = {
        ...state,
        enemyRaces: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default enemyReducer;

import React from "react";
import { connect } from "react-redux";
import "./enemies.layout.scss";
import Input from "../../shared/input/input.component";
import Modal from "../../shared/modal/modal.component";
import Select from "../../shared/select/select.component";
import Button from "../../shared/button/button.component";
import EnemyDescription from "../../core/components/enemyDescription/enemyDescription.component";
import addIcon from "../../../assets/icons/plus-solid.svg";
class EnemiesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItemId: null,
      searchText: "",
      modalOpen: false,
      form: {
        idEnemyRace: { value: null, valid: false },
        idAlignment: { value: null, valid: false },
        name: { value: "", valid: false },
        level: { value: "", valid: false },
        life: { value: "", valid: false },
      },
    };
    this.load();
  }
  load = () => {
    const action = {
      type: "list",
      module: "enemy",
      value: null,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    const alignmentsAction = {
      type: "list",
      module: "alignment",
      value: null,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(alignmentsAction);
    const enemyRacesAction = {
      type: "list",
      module: "enemyRace",
      value: null,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(enemyRacesAction);
  };
  onSelect = (item) => {
    this.setState({
      selectedItemId:
        !this.state.selectedItemId ||
        (this.state.selectedItemId && this.state.selectedItemId !== item.id)
          ? item.id
          : null,
    });
  };
  onClose = () => {
    this.setState({ selectedItemId: null });
  };
  onChangeSearch = (value) => {
    this.setState({ searchText: value.value });
  };
  onOpenEdit = (item) => {
    this.setState({ modalOpen: true });
  };
  onCloseEdit = (item) => {
    this.setState({ modalOpen: false });
  };
  onChangeName = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        name: value,
      },
    });
  };
  onChangeLevel = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        level: value,
      },
    });
  };
  onChangeLife = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        life: value,
      },
    });
  };
  onSelectRace = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        idEnemyRace: {
          ...this.state.form.idEnemyRace,
          value: value.id,
          valid: true,
        },
      },
    });
  };
  onSelectAlignment = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        idAlignment: {
          ...this.state.form.idAlignment,
          value: value ? value.id : value,
          valid: true,
        },
      },
    });
  };
  onSave = () => {
    const action = {
      type: "CREATE",
      module: "enemy",
      value: {
        idEnemyRace: this.state.form.idEnemyRace.value,
        idAlignment: this.state.form.idAlignment.value,
        name: this.state.form.name.value,
        level: this.state.form.level.value,
        life: this.state.form.life.value,
      },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({
      form: {
        idEnemyRace: { value: null, valid: false },
        idAlignment: { value: null, valid: false },
        name: { value: "", valid: false },
        level: { value: "", valid: false },
        life: { value: "", valid: false },
      },
      modalOpen: false,
    });
  };
  render() {
    const selectedItem = this.props.enemyReducer.enemies.find(
      (enemy) => enemy.id === this.state.selectedItemId
    );
    var enemies = this.props.enemyReducer.enemies.filter(
      (enemy) =>
        enemy.deleted === 0 &&
        enemy.name.toUpperCase().search(this.state.searchText.toUpperCase()) !==
          -1
    );
    return (
      <div className="enemiesLayout">
        <div className="navigation">
          <div className="header">
            <div className="title">Enemies ({enemies.length})</div>
            <div className="actions">
              <div className="action" onClick={() => this.onOpenEdit(null)}>
                <img src={addIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="search">
            <Input
              id="search"
              type="search"
              placeHolder="Search"
              value={this.state.searchText}
              onChange={this.onChangeSearch}
              canErase
            />
          </div>
          <div className="wrapper">
            {enemies.map((enemy, index) => (
              <div
                key={index}
                className={
                  selectedItem && selectedItem.id === enemy.id
                    ? "item active"
                    : "item"
                }
                onClick={() => this.onSelect(enemy)}
              >
                {enemy.name}
              </div>
            ))}
          </div>
        </div>
        {selectedItem ? (
          <div className="content">
            <EnemyDescription item={selectedItem} onClose={this.onClose} />
          </div>
        ) : (
          <div className="content empty">No selected enemy</div>
        )}
        {this.state.modalOpen && (
          <Modal
            title="New enemy"
            onClose={this.onCloseEdit}
            open={this.state.modalOpen}
          >
            <div className="enemyModal">
              <div className="form">
                <div className="field">
                  <Input
                    id="name"
                    placeHolder="Name"
                    onChange={this.onChangeName}
                    canErase
                    required
                  />
                </div>
                <div className="field">
                  <Select
                    id="idEnemyRace"
                    labelKey="name"
                    valueKey="id"
                    searchKey="name"
                    placeHolder="Choice race"
                    items={this.props.enemyReducer.enemyRaces.filter(
                      (enemyRace) => enemyRace.deleted === 0
                    )}
                    onChange={this.onSelectRace}
                    required
                  />
                </div>
                <div className="field">
                  <Select
                    id="idAligment"
                    labelKey="name"
                    valueKey="id"
                    searchKey="name"
                    placeHolder="Choice aligment"
                    onChange={this.onSelectAlignment}
                    items={this.props.alignmentReducer.alignments.filter(
                      (alignment) => alignment.deleted === 0
                    )}
                    required
                  />
                </div>
                <div className="field">
                  <Input
                    id="level"
                    placeHolder="Level"
                    type="number"
                    min="1"
                    max="100"
                    onChange={this.onChangeLevel}
                    canErase
                    required
                  />
                </div>
                <div className="field">
                  <Input
                    id="life"
                    placeHolder="Life"
                    type="number"
                    min="1"
                    max="100"
                    onChange={this.onChangeLife}
                    canErase
                    required
                  />
                </div>
              </div>
              <div className="actions">
                <Button
                  disabled={
                    this.state.form.name.valid &&
                    this.state.form.level.valid &&
                    this.state.form.life.valid &&
                    this.state.form.idAlignment.valid &&
                    this.state.form.idEnemyRace.valid
                      ? false
                      : true
                  }
                  onClick={this.onSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(EnemiesLayout);

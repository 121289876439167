import React from "react";
import { connect } from "react-redux";
import "./playerItem.component.scss";
import PlayerLife from "../../../../core/components/playerLife/playerLife.component";
import TryVsDead from "../../../../core/components/tryVsDead/tryVsDead.component";
import RaceInfo from "../../../../core/components/raceInfo/raceInfo.component";
import ClassInfo from "../../../../core/components/classInfo/classInfo.component";
import PlayerCharacteristics from "../../../../core/components/playerCharacteristics/playerCharacteristics.component";
import PlayerLevel from "../../../../core/components/playerLevel/playerLevel.component";
import PlayerMaxLife from "../../../../core/components/playerMaxLife/playerMaxLife.component";

class PlayerItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const currentCharacter = this.props.item;
    return (
      <div className="playerItem">
        <div className="nameBox">
          {currentCharacter ? currentCharacter.name : this.props.item.username}
        </div>
        {currentCharacter && (
          <div className="playerLifeBox">
            <div className="row">
              <RaceInfo item={currentCharacter} />
              <ClassInfo item={currentCharacter} />
            </div>
            <div className="row">
              <PlayerLevel item={currentCharacter} />
              <PlayerMaxLife item={currentCharacter} />
            </div>
            <div className="row">
              <PlayerLife item={currentCharacter} />
            </div>
            {currentCharacter.dead === 1 && (
              <div className="row">
                <TryVsDead item={currentCharacter} />
              </div>
            )}
            <div className="row">
              <PlayerCharacteristics item={currentCharacter} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayerItem);

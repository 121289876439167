import React from "react";
import { connect } from "react-redux";
import "./mapSelectorItem.component.scss";

class MapSelectorItem extends React.Component {
  render() {
    return (
      <div
        className={
          this.props.gameReducer.selectedGame.currentMap &&
          this.props.gameReducer.selectedGame.currentMap.id ===
            this.props.item.id
            ? "mapSelectorItem active"
            : "mapSelectorItem"
        }
        onClick={() => {
          this.props.onSelect && this.props.onSelect(this.props.item);
        }}
      >
        {this.props.item.name}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(MapSelectorItem);

import React from "react";
import { connect } from "react-redux";
import "./mainNav.component.scss";
import MainNavItem from "./mainNavItem/mainNavItem.component";

import dashboardIcon from "../../../../assets/icons/gauge-solid.svg";
import mapIcon from "../../../../assets/icons/map-solid.svg";
import playersIcon from "../../../../assets/icons/users-solid.svg";
import settingsIcon from "../../../../assets/icons/gear-solid.svg";
import enemiesIcon from "../../../../assets/icons/dragon-solid.svg";
import itemsIcon from "../../../../assets/icons/shield-solid.svg";
import placesIcon from "../../../../assets/icons/place-of-worship-solid.svg";
import personnagesIcon from "../../../../assets/icons/user-solid.svg";

class MainNav extends React.Component {
  constructor(props) {
    super(props);
    this.items = [
      {
        icon: dashboardIcon,
        path: "",
      },
      {
        icon: mapIcon,
        path: "map",
      },
      {
        icon: playersIcon,
        path: "players",
      },
      {
        icon: placesIcon,
        path: "places",
      },
      {
        icon: personnagesIcon,
        path: "personnages",
      },
      {
        icon: enemiesIcon,
        path: "enemies",
      },
      {
        icon: itemsIcon,
        path: "items",
      },
      {
        icon: settingsIcon,
        path: "settings",
      },
    ];
  }
  render() {
    return (
      <>
        <div className="mainNav">
          <div className="mainBox">
            {this.items.map((item, index) => (
              <div className="item" key={index}>
                <MainNavItem
                  item={item}
                  first={index === 0}
                  last={index === this.items.length - 1}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(MainNav);

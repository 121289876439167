import React from "react";
import { connect } from "react-redux";
import "./dashboard.layout.scss";
import BattleManager from "../../core/components/battleManager/battleManager.component";
import GameClock from "../../core/components/gameClock/gameClock.component";
import SessionClock from "../../core/components/sessionClock/sessionClock.component";

class DashboardLayout extends React.Component {
  render() {
    return (
      <div className="dashboardLayout">
        <BattleManager />

        <div className="test">
          <GameClock />
        </div>
        <div className="test">
          <SessionClock />
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(DashboardLayout);

import React from "react";
import { connect } from "react-redux";
import "./playerCharacteristics.component.scss";

import strengthIcon from "../../../../assets/icons/hand-fist-solid.svg";
import dexterityIcon from "../../../../assets/icons/crosshairs-solid.svg";
import constitutionIcon from "../../../../assets/icons/shield-solid.svg";
import intelligenceIcon from "../../../../assets/icons/brain-solid.svg";
import sagacityIcon from "../../../../assets/icons/yin-yang-solid.svg";
import charismaIcon from "../../../../assets/icons/star-solid.svg";
import PlayerCharacteristicItem from "./playerCharacteristicItem/playerCharacteristicItem.component";

import lockIcon from "../../../../assets/icons/lock-solid.svg";
import lockOpenIcon from "../../../../assets/icons/lock-open-solid.svg";

class PlayerCharacteristics extends React.Component {
  constructor(props) {
    super(props);
    this.items = [
      {
        icon: strengthIcon,
        key: "strength",
      },
      {
        icon: dexterityIcon,
        key: "dexterity",
      },
      {
        icon: constitutionIcon,
        key: "constitution",
      },
      {
        icon: intelligenceIcon,
        key: "intelligence",
      },
      {
        icon: sagacityIcon,
        key: "sagacity",
      },
      {
        icon: charismaIcon,
        key: "charisma",
      },
    ];
  }
  onToggleCanEditCharacteristic = (id) => {
    const action = {
      type: "EDIT_CAN_EDIT_CHARACTERISTIC",
      module: "playerCharacter",
      value: { id: id, value: this.props.item.canEditCharacteristic ? 0 : 1 },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="playerCharacteristics">
        <div className="header">
          <div className="actions"></div>
          <div className="title">Characteristics</div>
          <div className="actions">
            <div
              className={
                this.props.item.canEditCharacteristic
                  ? "action"
                  : "action locked"
              }
              onClick={() => {
                this.onToggleCanEditCharacteristic(this.props.item.id);
              }}
            >
              <img
                src={
                  this.props.item.canEditCharacteristic
                    ? lockOpenIcon
                    : lockIcon
                }
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="wrapper">
          {this.items.map((characteristic, index) => (
            <div className="item" key={index}>
              <PlayerCharacteristicItem
                id={this.props.item.id}
                item={characteristic}
                value={this.props.item[characteristic.key]}
                bonus={this.props.item[characteristic.bonusKey]}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayerCharacteristics);

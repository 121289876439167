import React from "react";
import "./input.component.scss";

import SearchInput from "./search/input-search";
import TextareaInput from "./textarea/input-textarea";
import PasswordInput from "./password/input-password";
import DateInput from "./date/input-date";
import PhoneInput from "./phone/input-phone";
import EmailInput from "./email/input-email";
import UrlInput from "./url/input-url";

import ReloadIcon from "../../../assets/icons/rotate-right-solid.svg";
import EraseIcon from "../../../assets/icons/xmark-solid.svg";

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.initialValue = this.props.value ? this.props.value : "";
    this.lastValidValue = this.props.value ? this.props.value : "";
    this.state = {
      value: this.props.value ? this.props.value : "",
      valid: null,
      validationText: null,
    };
  }
  change = (value, forceReset, reason) => {
    const baseValue = this.lastValidValue;
    this.setState({ value: value });
    const result = this.validate(value);

    this.lastValidValue =
      forceReset && reason
        ? reason === "reset"
          ? this.initialValue
          : ""
        : result.valid
        ? value
        : baseValue;
    this.props.id && this.props.onChange
      ? this.props.onChange({
          id: this.props.id,
          value:
            forceReset && reason
              ? reason === "reset"
                ? this.initialValue
                : this.props.required
                ? baseValue
                : ""
              : result.valid
              ? value
              : baseValue,
          valid: result.valid,
        })
      : console.error("id or onChange props required");
  };
  validate = (value) => {
    if (this.props.required && value.length === 0) {
      const error = { valid: false, validationText: "Field required" };
      this.setState(error);
      return error;
    }
    if (
      this.props.type !== "number" &&
      this.props.min &&
      this.props.min > 0 &&
      value.length < this.props.min
    ) {
      const error = { valid: false, validationText: "Min " + this.props.min };
      this.setState(error);
      return error;
    }
    if (
      this.props.type !== "number" &&
      this.props.max &&
      this.props.max > 0 &&
      value.length > this.props.max
    ) {
      const error = { valid: false, validationText: "Max " + this.props.max };
      this.setState(error);
      return error;
    }
    if (
      this.props.type === "number" &&
      this.props.min &&
      value < parseFloat(this.props.min)
    ) {
      const error = { valid: false, validationText: "Min " + this.props.min };
      this.setState(error);
      return error;
    }
    if (
      this.props.type === "number" &&
      this.props.max &&
      value > parseFloat(this.props.max)
    ) {
      const error = { valid: false, validationText: "Max " + this.props.max };
      this.setState(error);
      return error;
    }
    const validation = { valid: true, validationText: null };
    this.setState(validation);
    return validation;
  };
  onCancel = () => {
    this.change(this.initialValue, true, "reset");
  };
  onErase = () => {
    this.change("", true, "erase");
  };
  render() {
    switch (this.props.type) {
      case "email":
        return (
          <EmailInput
            id={this.props.id}
            placeHolder={this.props.placeHolder}
            value={this.props.value}
            disabled={this.props.disabled ? this.props.disabled : false}
            required={this.props.required ? this.props.required : false}
            canCancel={this.props.canCancel ? this.props.canCancel : false}
            canErase={this.props.canErase ? this.props.canErase : false}
            onChange={this.props.onChange ? this.props.onChange : this.change}
          />
        );
      case "search":
        return (
          <SearchInput
            id={this.props.id}
            placeHolder={this.props.placeHolder}
            value={this.props.value}
            min={this.props.min && this.props.min}
            max={this.props.max && this.props.max}
            disabled={this.props.disabled ? this.props.disabled : false}
            required={this.props.required ? this.props.required : false}
            canCancel={this.props.canCancel ? this.props.canCancel : false}
            canErase={this.props.canErase ? this.props.canErase : false}
            onChange={this.props.onChange ? this.props.onChange : this.change}
          />
        );
      case "textarea":
        return (
          <TextareaInput
            id={this.props.id}
            placeHolder={this.props.placeHolder}
            value={this.props.value}
            min={this.props.min && this.props.min}
            max={this.props.max && this.props.max}
            disabled={this.props.disabled ? this.props.disabled : false}
            required={this.props.required ? this.props.required : false}
            canCancel={this.props.canCancel ? this.props.canCancel : false}
            canErase={this.props.canErase ? this.props.canErase : false}
            onChange={this.props.onChange ? this.props.onChange : this.change}
          />
        );
      case "phone":
        return (
          <PhoneInput
            id={this.props.id}
            placeHolder={this.props.placeHolder}
            value={this.props.value}
            disabled={this.props.disabled ? this.props.disabled : false}
            required={this.props.required ? this.props.required : false}
            canCancel={this.props.canCancel ? this.props.canCancel : false}
            canErase={this.props.canErase ? this.props.canErase : false}
            onChange={this.props.onChange ? this.props.onChange : this.change}
          />
        );
      case "password":
        return (
          <PasswordInput
            id={this.props.id}
            placeHolder={this.props.placeHolder}
            value={this.props.value}
            min={this.props.min && this.props.min}
            max={this.props.max && this.props.max}
            level={this.props.level ? this.props.level : 1}
            disabled={this.props.disabled ? this.props.disabled : false}
            required={this.props.required ? this.props.required : false}
            canErase={this.props.canErase ? this.props.canErase : false}
            onChange={this.props.onChange ? this.props.onChange : this.change}
          />
        );
      case "url":
        return (
          <UrlInput
            id={this.props.id}
            placeHolder={this.props.placeHolder}
            value={this.props.value}
            disabled={this.props.disabled ? this.props.disabled : false}
            required={this.props.required ? this.props.required : false}
            canCancel={this.props.canCancel ? this.props.canCancel : false}
            canErase={this.props.canErase ? this.props.canErase : false}
            onChange={this.props.onChange ? this.props.onChange : this.change}
          />
        );
      case "date":
        return (
          <DateInput
            id={this.props.id}
            placeHolder={this.props.placeHolder}
            value={this.props.value}
            min={this.props.min && this.props.min}
            max={this.props.max && this.props.max}
            disabled={this.props.disabled ? this.props.disabled : false}
            required={this.props.required ? this.props.required : false}
            canCancel={this.props.canCancel ? this.props.canCancel : false}
            canErase={this.props.canErase ? this.props.canErase : false}
            onChange={this.change}
          />
        );
      default:
        return (
          <div className="inputMainBox">
            <div
              className={
                this.props.disabled
                  ? "inputBoxDisabled"
                  : this.state.valid === false
                  ? "inputBoxError"
                  : "inputBox"
              }
            >
              <input
                className="input"
                placeholder={
                  this.props.placeHolder ? this.props.placeHolder : ""
                }
                type={this.props.type === "number" ? "number" : "text"}
                step={
                  this.props.type === "number" && this.props.step
                    ? parseFloat(this.props.step)
                    : 1
                }
                min={
                  this.props.type === "number" && this.props.min
                    ? this.props.min
                    : 0
                }
                max={
                  this.props.type === "number" && this.props.max
                    ? this.props.max
                    : false
                }
                value={this.state.value}
                disabled={this.props.disabled ? this.props.disabled : false}
                onChange={(event) => {
                  this.change(event.target.value);
                }}
              />
              {!this.props.disabled &&
                (this.props.canCancel || this.props.canErase) && (
                  <div className="actionsBox">
                    {this.props.canCancel && (
                      <div
                        className="actionBox"
                        title="Cancel"
                        onClick={this.onCancel}
                      >
                        <img src={ReloadIcon} width={16} alt="" />
                      </div>
                    )}
                    {this.props.canErase && (
                      <div
                        className="actionBox"
                        title="Erase"
                        onClick={this.onErase}
                      >
                        <img src={EraseIcon} width={16} alt="" />
                      </div>
                    )}
                  </div>
                )}
            </div>

            {this.state.valid === false && (
              <div className="errorBox">
                {this.state.validationText
                  ? this.state.validationText
                  : "error"}
              </div>
            )}
          </div>
        );
    }
  }
}
export default Input;

import React from "react";
import "./select.component.scss";
import Input from "../input/input.component";
import openIcon from "../../../assets/icons/chevron-down-solid.svg";
import closeIcon from "../../../assets/icons/chevron-up-solid.svg";

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false,
      value: this.props.value ? this.props.value : "",
      searchText: "",
    };
  }
  onToggleOpen = () => {
    this.setState({ open: !this.state.open });
  };
  onSelect = (label, item) => {
    this.setState({ open: false, value: label });
    this.props.onChange && this.props.onChange(item);
  };
  onChangeSearch = (value) => {
    this.setState({ searchText: value.value });
  };
  render() {
    return (
      <div className={this.props.disabled ? "select disabled" : "select"}>
        <div className="value" onClick={this.onToggleOpen}>
          {this.state.value !== ""
            ? this.state.value
            : this.props.placeHolder
            ? this.props.placeHolder
            : ""}
        </div>
        <div className="actions">
          <div className="action" onClick={this.onToggleOpen}>
            <img
              src={
                this.state.open
                  ? this.props.openUp
                    ? openIcon
                    : closeIcon
                  : this.props.openUp
                  ? closeIcon
                  : openIcon
              }
              alt=""
            />
          </div>
        </div>
        {this.state.open && (
          <div className={this.props.openUp ? "list up" : "list"}>
            {this.props.searchKey && (
              <div className="searchBox">
                <Input
                  id="search"
                  placeHolder="Search"
                  value={this.state.searchText}
                  onChange={this.onChangeSearch}
                  canErase
                />
              </div>
            )}

            <div className={this.props.openUp ? "wrapper up" : "wrapper"}>
              {!this.props.required && (
                <div
                  className="item"
                  onClick={() => {
                    this.onSelect("", null);
                  }}
                >
                  Nothing
                </div>
              )}
              {this.props.items
                .filter((item) => {
                  return this.props.searchKey
                    ? item[this.props.searchKey].search(
                        this.state.searchText
                      ) !== -1
                    : true;
                })
                .map((item, index) => (
                  <div
                    key={index}
                    className="item"
                    onClick={() => {
                      this.onSelect(item[this.props.labelKey], item);
                    }}
                  >
                    {item[this.props.labelKey]}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Select;

import React from "react";
import "./input-search.component.scss";

import ReloadIcon from "../../../../assets/icons/rotate-right-solid.svg";
import EraseIcon from "../../../../assets/icons/xmark-solid.svg";
import SearchIcon from "../../../../assets/icons/magnifying-glass-solid.svg";
class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.initialValue = this.props.value ? this.props.value : "";
    this.lastValidValue = this.props.value ? this.props.value : "";
    this.state = {
      value: this.props.value ? this.props.value : "",
      valid: null,
      validationText: null,
    };
  }
  change = (value, forceReset, reason) => {
    const baseValue = this.lastValidValue;
    this.setState({ value: value });
    const result = this.validate(value);

    this.lastValidValue =
      forceReset && reason
        ? reason === "reset"
          ? this.initialValue
          : ""
        : result.valid
        ? value
        : baseValue;
    this.props.id && this.props.onChange
      ? this.props.onChange({
          id: this.props.id,
          value:
            forceReset && reason
              ? reason === "reset"
                ? this.initialValue
                : this.props.required
                ? baseValue
                : ""
              : result.valid
              ? value
              : baseValue,
          valid: result.valid,
        })
      : console.error("id or onChange props required");
  };
  validate = (value) => {
    if (this.props.required && value.length === 0) {
      const error = { valid: false, validationText: "Field required" };
      this.setState(error);
      return error;
    }
    if (this.props.min && this.props.min > 0 && value.length < this.props.min) {
      const error = { valid: false, validationText: "Min " + this.props.min };
      this.setState(error);
      return error;
    }
    if (this.props.max && this.props.max > 0 && value.length > this.props.max) {
      const error = { valid: false, validationText: "Max " + this.props.max };
      this.setState(error);
      return error;
    }
    const validation = { valid: true, validationText: null };
    this.setState(validation);
    return validation;
  };
  onCancel = () => {
    this.change(this.initialValue, true, "reset");
  };
  onErase = () => {
    this.change("", true, "erase");
  };
  render() {
    return (
      <div className="inputSearchMainBox">
        <div
          className={
            this.props.disabled
              ? "inputBoxDisabled"
              : this.state.valid === false
              ? "inputBoxError"
              : "inputBox"
          }
        >
          <div className="inputIcon">
            <img src={SearchIcon} width={16} alt="" />
          </div>
          <input
            className="input"
            placeholder={this.props.placeHolder ? this.props.placeHolder : ""}
            value={this.state.value}
            disabled={this.props.disabled ? this.props.disabled : false}
            onChange={(event) => {
              this.change(event.target.value);
            }}
          />
          {!this.props.disabled &&
            (this.props.canCancel || this.props.canErase) && (
              <div className="actionsBox">
                {this.props.canCancel && (
                  <div
                    className="actionBox"
                    title="Cancel"
                    onClick={this.onCancel}
                  >
                    <img src={ReloadIcon} width={16} alt="" />
                  </div>
                )}
                {this.props.canErase && (
                  <div
                    className="actionBox"
                    title="Erase"
                    onClick={this.onErase}
                  >
                    <img src={EraseIcon} width={16} alt="" />
                  </div>
                )}
              </div>
            )}
        </div>

        {this.state.valid === false && (
          <div className="errorBox">
            {this.state.validationText ? this.state.validationText : "error"}
          </div>
        )}
      </div>
    );
  }
}
export default SearchInput;

import React from "react";
import { connect } from "react-redux";
import "./interface.component.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from "../layout/layout.component";

import NotFoundLayout from "../../../layouts/notFoundLayout/notFoundLayout.layout";
import DashboardLayout from "../../../layouts/dashboard/dashboard.layout";
import MapLayout from "../../../layouts/map/map.layout";
import PlayersLayout from "../../../layouts/players/players.layout";
import PlayerLayout from "../../../layouts/player/player.layout";
import GameSettingsLayout from "../../../layouts/gameSettings/gameSettings.layout";
import EnemiesLayout from "../../../layouts/enemies/enemies.layout";
import ItemsLayout from "../../../layouts/items/items.layout";
import PlacesLayout from "../../../layouts/places/places.layout";
import PersonnagesLayout from "../../../layouts/personnages/personnages.layout";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFoundLayout />,
    children: [
      {
        path: "",
        element: <DashboardLayout />,
      },
      {
        path: "map",
        element: <MapLayout />,
      },
      {
        path: "players",
        element: <PlayersLayout />,
      },
      {
        path: "player",
        element: <PlayerLayout />,
      },
      {
        path: "places",
        element: <PlacesLayout />,
      },
      {
        path: "personnages",
        element: <PersonnagesLayout />,
      },
      {
        path: "enemies",
        element: <EnemiesLayout />,
      },
      {
        path: "items",
        element: <ItemsLayout />,
      },
      {
        path: "settings",
        element: <GameSettingsLayout />,
      },
    ],
  },
]);

class Interface extends React.Component {
  render() {
    return (
      <div className="interface">
        <RouterProvider router={router} />
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(Interface);

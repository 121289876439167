import React from "react";
import { connect } from "react-redux";
import "./mapSelector.component.scss";
import MapSelectorItem from "./mapSelectorItem/mapSelectorItem.component";

class MapSelector extends React.Component {
  constructor(props) {
    super(props);
    const action = {
      type: "list",
      module: "gameMap",
      value: null,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  }
  onSelectMap = (map) => {
    const action = {
      type: "SELECT_GAME_MAP",
      module: "game",
      value: {
        gameId: this.props.gameReducer.selectedGame.id,
        value:
          this.props.gameReducer.selectedGame.currentMap &&
          this.props.gameReducer.selectedGame.currentMap.id === map.id
            ? null
            : map,
      },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="mapSelector">
        {this.props.mapReducer.maps.map((map, index) => (
          <div key={index}>
            <MapSelectorItem item={map} onSelect={this.onSelectMap} />
          </div>
        ))}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(MapSelector);

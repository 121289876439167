import React from "react";
import { connect } from "react-redux";
import "./playerCharacterSelector.component.scss";

class PlayerCharacterSelector extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="playerCharacterSelector">
        <div className="title">
          {this.props.gameReducer.selectedGame &&
          this.props.gameReducer.selectedGame.players.length > 0 ? (
            this.props.gameReducer.selectedGame.players.map((player, index) => (
              <div key={index}>{player.username}</div>
            ))
          ) : (
            <div>empty</div>
          )}
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayerCharacterSelector);

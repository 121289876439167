import { combineReducers } from "redux";
import appReducer from "./app.reducer";
import sessionReducer from "./session.reducer";
import gameReducer from "./game.reducer";
import mapReducer from "./map.reducer";
import enemyReducer from "./enemy.reducer";
import alignmentReducer from "./alignment.reducer";
import placeReducer from "./place.reducer";
import personnageReducer from "./personnage.reducer";

export default combineReducers({
  appReducer,
  sessionReducer,
  gameReducer,
  mapReducer,
  enemyReducer,
  alignmentReducer,
  placeReducer,
  personnageReducer,
});

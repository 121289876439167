const initialState = {
  personnages: [],
  personnageTypes: [],
  personnageRaces: [],
};
function personnageReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "UPDATE_PERSONNAGES":
      nextState = {
        ...state,
        personnages: action.value,
      };
      return nextState || state;
    case "UPDATE_PERSONNAGE_TYPES":
      nextState = {
        ...state,
        personnageTypes: action.value,
      };
      return nextState || state;
    case "UPDATE_PERSONNAGE_RACES":
      nextState = {
        ...state,
        personnageRaces: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default personnageReducer;

const initialState = {
  selectedGame: null,
  selectedCharacter: null,
  games: [],
};
function gameReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "UPDATE_GAMES":
      const selectedGame = state.selectedGame
        ? action.value.find((game) => game.id === state.selectedGame.id)
        : state.selectedGame;

      const selectedCharacterId = state.selectedCharacter
        ? state.selectedCharacter.id
        : null;
      const currentPlayer =
        selectedGame && state.selectedCharacter
          ? selectedGame.players.find((player) =>
              player.characters.find(
                (character) => character.id === selectedCharacterId
              )
            )
          : null;
      const selectedCharacter = currentPlayer
        ? currentPlayer.characters.find(
            (character) => character.id === state.selectedCharacter.id
          )
        : state.selectedCharacter;

      nextState = {
        ...state,
        games: action.value,
        selectedGame: selectedGame,
        selectedCharacter: selectedCharacter,
      };
      return nextState || state;
    case "SELECT_GAME":
      nextState = {
        ...state,
        selectedGame: action.value,
      };
      return nextState || state;
    case "UNSELECT_GAME":
      nextState = {
        ...state,
        selectedGame: null,
      };
      return nextState || state;
    case "SELECT_CHARACTER":
      nextState = {
        ...state,
        selectedCharacter: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default gameReducer;

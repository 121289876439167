import React from "react";
import { connect } from "react-redux";
import "./sessionClock.component.scss";

import playIcon from "../../../../assets/icons/play-solid.svg";
import pauseIcon from "../../../../assets/icons/pause-solid.svg";

class GameClock extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      onPlay: false,
    };
  }
  onPlay = () => {
    this.setState({ onPlay: true });
    this.timer = setInterval(() => {
      const action = {
        type: "EDIT_REAL_DURATION",
        module: "game",
        value: {
          id: this.props.gameReducer.selectedGame.id,
          value: this.props.gameReducer.selectedGame.realDuration + 1,
        },
        socket: {
          send: true,
        },
      };
      this.props.dispatch(action);
    }, 1000);
  };
  onPause = () => {
    this.setState({ onPlay: false });
    clearInterval(this.timer);
  };
  render() {
    const duration = renderDuration(
      this.props.gameReducer.selectedGame.realDuration
    );
    return (
      <div className="sessionClock">
        <div className="content">
          <div>Day {duration.days}</div>
          <div>
            {pad2(duration.hours)}h{pad2(duration.minutes)}
          </div>
        </div>
        <div className="actions">
          <div
            className="action"
            onClick={this.state.onPlay ? this.onPause : this.onPlay}
          >
            {this.state.onPlay ? (
              <img src={pauseIcon} alt="" />
            ) : (
              <img src={playIcon} alt="" />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
function renderDuration(seconds) {
  if (typeof seconds !== "number" || isNaN(seconds) || seconds < 0) {
    return { error: "Invalid input" };
  }

  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: remainingSeconds,
  };
}

function pad2(value) {
  return value > 9 ? value : "0" + value;
}
export default connect(StateToProps, DispatchToProps)(GameClock);

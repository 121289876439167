import React from "react";
import { connect } from "react-redux";
import "./playerLevel.component.scss";

class PlayerLevel extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = (id, value) => {
    const action = {
      type: "EDIT_LEVEL",
      module: "playerCharacter",
      value: { id: id, value: value },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  render() {
    return (
      <div className="playerLevel">
        <div className="header">
          <div className="title">Level</div>
        </div>

        <div className="actions">
          <div
            className="action"
            onClick={() => {
              this.onChange(this.props.item.id, this.props.item.level - 1);
            }}
          >
            -
          </div>
          <div className="level">{this.props.item.level}</div>
          <div
            className="action"
            onClick={() => {
              this.onChange(this.props.item.id, this.props.item.level + 1);
            }}
          >
            +
          </div>
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlayerLevel);

import React from "react";
import "./button.component.scss";

class Button extends React.Component {
  render() {
    return (
      <div
        className={
          this.props.disabled && this.props.disabled !== false
            ? "button disabled"
            : "button"
        }
        onClick={
          this.props.onClick && this.props.disabled !== true
            ? this.props.onClick
            : () => {}
        }
      >
        {this.props.children}
      </div>
    );
  }
}
export default Button;

import React from "react";
import { connect } from "react-redux";
import "./gameSelector.component.scss";
import GameSelectorItem from "./gameSelectorItem/gameSelectorItem.component";
import Tabs from "../../../shared/tabs/tabs.component";
import Button from "../../../shared/button/button.component";
import Modal from "../../../shared/modal/modal.component";
import Input from "../../../shared/input/input.component";

class GameSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newModalOpen: false,
      newGameFormValid: false,
      newGameName: "",
    };
    const action = {
      type: "list",
      module: "game",
      value: null,
      socket: {
        send: true,
      },
    };

    !this.props.gameReducer.selectedGame && this.props.dispatch(action);
  }
  onSelectGame = (game) => {
    const action = {
      type: "SELECT_GAME",
      module: "game",
      value: game,
      socket: {
        send: true,
      },
    };

    this.props.dispatch(action);
  };
  onToggleNewModal = () => {
    this.setState({ newModalOpen: !this.state.newModalOpen });
  };
  onChangeNewGameName = (value) => {
    // console.log(value);
    this.setState({ newGameFormValid: value.valid, newGameName: value.value });
  };
  onCreateGame = () => {
    this.setState({
      newModalOpen: false,
      newGameFormValid: false,
      newGameName: "",
    });
    const action = {
      type: "CREATE",
      module: "game",
      value: { name: this.state.newGameName },
      socket: {
        send: true,
      },
    };

    !this.props.gameReducer.selectedGame && this.props.dispatch(action);
  };
  render() {
    return (
      <div className="gameSelector">
        <div className="mainBox">
          <div className="header">
            <h1>Select game</h1>
          </div>
          <div className="wrapper">
            <Tabs
              items={[
                {
                  label: "In progress",
                  content: (
                    <>
                      {this.props.gameReducer.games
                        .filter(
                          (item) =>
                            item.deleted === 0 && item.gameMaster === null
                        )
                        .map((game, index) => (
                          <div key={index}>
                            <GameSelectorItem
                              item={game}
                              onSelect={this.onSelectGame}
                            />
                          </div>
                        ))}
                    </>
                  ),
                },
                {
                  label: "Deleted",
                  content: (
                    <>
                      {this.props.gameReducer.games
                        .filter((item) => item.deleted === 1)
                        .map((game, index) => (
                          <div key={index}>
                            <GameSelectorItem
                              item={game}
                              onSelect={this.onSelectGame}
                            />
                          </div>
                        ))}
                    </>
                  ),
                },
              ]}
            />
            {/* {this.props.gameReducer.games
              .filter((item) => item.deleted === 0)
              .map((game, index) => (
                <div key={index}>
                  <GameSelectorItem item={game} onSelect={this.onSelectGame} />
                </div>
              ))} */}
          </div>
          <div className="actions">
            <Button onClick={this.onToggleNewModal}>New game</Button>
          </div>
        </div>
        {this.state.newModalOpen && (
          <Modal title="New game" onClose={this.onToggleNewModal}>
            <div className="newModal">
              <div className="form">
                <Input
                  id="newGameName"
                  value={this.state.newGameName}
                  placeHolder="Game name"
                  onChange={this.onChangeNewGameName}
                  required
                  canErase
                />
              </div>
              <div className="actions">
                <Button
                  onClick={this.onCreateGame}
                  disabled={!this.state.newGameFormValid}
                >
                  Create
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(GameSelector);

import React from "react";
import "./input-password.component.scss";

import ReloadIcon from "../../../../assets/icons/rotate-right-solid.svg";
import EraseIcon from "../../../../assets/icons/xmark-solid.svg";
import LockIcon from "../../../../assets/icons/lock-solid.svg";
import ShowIcon from "../../../../assets/icons/eye-solid.svg";
import NotShowIcon from "../../../../assets/icons/eye-slash-solid.svg";

class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.initialValue = this.props.value ? this.props.value : "";
    this.lastValidValue = this.props.value ? this.props.value : "";
    this.state = {
      value: this.props.value ? this.props.value : "",
      valid: null,
      validationText: null,
      show: false,
    };
  }
  onToggleShow = () => {
    this.setState({ show: !this.state.show });
  };
  change = (value, forceReset, reason) => {
    const baseValue = this.lastValidValue;
    this.setState({ value: value });
    const result = this.validate(value);

    this.lastValidValue =
      forceReset && reason
        ? reason === "reset"
          ? this.initialValue
          : ""
        : result.valid
        ? value
        : baseValue;
    this.props.id && this.props.onChange
      ? this.props.onChange({
          id: this.props.id,
          value:
            forceReset && reason
              ? reason === "reset"
                ? this.initialValue
                : this.props.required
                ? baseValue
                : ""
              : result.valid
              ? value
              : baseValue,
          valid: result.valid,
        })
      : console.error("id or onChange props required");
  };
  validate = (value) => {
    if (this.props.required && value.length === 0) {
      const error = { valid: false, validationText: "Field required" };
      this.setState(error);
      return error;
    }
    if (this.props.min && this.props.min > 0 && value.length < this.props.min) {
      const error = { valid: false, validationText: "Min " + this.props.min };
      this.setState(error);
      return error;
    }
    if (this.props.max && this.props.max > 0 && value.length > this.props.max) {
      const error = { valid: false, validationText: "Max " + this.props.max };
      this.setState(error);
      return error;
    }
    const validation = { valid: true, validationText: null };
    this.setState(validation);
    return validation;
  };
  onCancel = () => {
    this.change(this.initialValue, true, "reset");
  };
  onErase = () => {
    this.change("", true, "erase");
  };
  render() {
    return (
      <div className="inputPasswordMainBox">
        <div
          className={
            this.props.disabled
              ? "inputBoxDisabled"
              : this.state.valid === false
              ? "inputBoxError"
              : "inputBox"
          }
        >
          <div className="inputIcon">
            <img src={LockIcon} width={16} alt="" />
          </div>
          <input
            className="input"
            placeholder={this.props.placeHolder ? this.props.placeHolder : ""}
            value={this.state.value}
            type={this.state.show ? "text" : "password"}
            disabled={this.props.disabled ? this.props.disabled : false}
            onChange={(event) => {
              this.change(event.target.value);
            }}
          />
          <div className="actionsBox">
            {this.props.canCancel &&
              !this.props.disabled(
                <div className="actionBox" onClick={this.onCancel}>
                  <img src={ReloadIcon} width={16} alt="" />
                </div>
              )}
            <div
              className="actionBox"
              title={this.state.show ? "Hide" : "Show"}
              onClick={this.onToggleShow}
            >
              <img
                src={this.state.show ? NotShowIcon : ShowIcon}
                width={16}
                alt=""
              />
            </div>
            {this.props.canErase && !this.props.disabled && (
              <div className="actionBox" title="Erase" onClick={this.onErase}>
                <img src={EraseIcon} width={16} alt="" />
              </div>
            )}
          </div>
        </div>

        {this.state.valid === false && (
          <div className="errorBox">
            {this.state.validationText ? this.state.validationText : "error"}
          </div>
        )}
      </div>
    );
  }
}
export default PasswordInput;

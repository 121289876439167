import React from "react";
import { connect } from "react-redux";
import "./enemyCharacteristics.component.scss";

import strengthIcon from "../../../../assets/icons/hand-fist-solid.svg";
import dexterityIcon from "../../../../assets/icons/crosshairs-solid.svg";
import constitutionIcon from "../../../../assets/icons/shield-solid.svg";
import intelligenceIcon from "../../../../assets/icons/brain-solid.svg";
import sagacityIcon from "../../../../assets/icons/yin-yang-solid.svg";
import charismaIcon from "../../../../assets/icons/star-solid.svg";
import EnemyCharacteristicItem from "./enemyCharacteristicItem/enemyCharacteristicItem.component";

class EnemyCharacteristics extends React.Component {
  constructor(props) {
    super(props);
    this.items = [
      {
        icon: strengthIcon,
        key: "strength",
      },
      {
        icon: dexterityIcon,
        key: "dexterity",
      },
      {
        icon: constitutionIcon,
        key: "constitution",
      },
      {
        icon: intelligenceIcon,
        key: "intelligence",
      },
      {
        icon: sagacityIcon,
        key: "sagacity",
      },
      {
        icon: charismaIcon,
        key: "charisma",
      },
    ];
  }
  render() {
    return (
      <div className="enemyCharacteristics">
        <div className="wrapper">
          {this.items.map((characteristic, index) => (
            <div className="item" key={index}>
              <EnemyCharacteristicItem
                id={this.props.item.id}
                item={characteristic}
                value={this.props.item[characteristic.key]}
                bonus={this.props.item[characteristic.bonusKey]}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(EnemyCharacteristics);

import React from "react";
import { connect } from "react-redux";
import "./places.layout.scss";
import Input from "../../shared/input/input.component";
import Modal from "../../shared/modal/modal.component";
import Button from "../../shared/button/button.component";
import addIcon from "../../../assets/icons/plus-solid.svg";
import PlaceDescription from "../../core/components/placeDescription/placeDescription.component";
class PlacesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItemId: null,
      searchText: "",
      modalOpen: false,
      form: {
        name: { value: "", valid: false },
      },
    };
    this.load();
  }
  load = () => {
    const action = {
      type: "list",
      module: "place",
      value: null,
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
  };
  onSelect = (item) => {
    this.setState({
      selectedItemId:
        !this.state.selectedItemId ||
        (this.state.selectedItemId && this.state.selectedItemId !== item.id)
          ? item.id
          : null,
    });
  };
  onClose = () => {
    this.setState({ selectedItemId: null });
  };
  onChangeSearch = (value) => {
    this.setState({ searchText: value.value });
  };
  onOpenEdit = (item) => {
    this.setState({ modalOpen: true });
  };
  onCloseEdit = (item) => {
    this.setState({ modalOpen: false });
  };
  onChangeName = (value) => {
    this.setState({
      form: {
        ...this.state.form,
        name: value,
      },
    });
  };
  onSave = () => {
    const action = {
      type: "CREATE",
      module: "place",
      value: {
        name: this.state.form.name.value,
        description: "",
      },
      socket: {
        send: true,
      },
    };
    this.props.dispatch(action);
    this.setState({
      form: {
        name: { value: "", valid: false },
      },
      modalOpen: false,
    });
  };
  render() {
    const selectedItem = this.props.placeReducer.places.find(
      (place) => place.id === this.state.selectedItemId
    );
    var places = this.props.placeReducer.places.filter(
      (place) =>
        place.deleted === 0 &&
        place.name.toUpperCase().search(this.state.searchText.toUpperCase()) !==
          -1
    );
    return (
      <div className="placesLayout">
        <div className="navigation">
          <div className="header">
            <div className="title">Places ({places.length})</div>
            <div className="actions">
              <div className="action" onClick={() => this.onOpenEdit(null)}>
                <img src={addIcon} alt="" />
              </div>
            </div>
          </div>
          <div className="search">
            <Input
              id="search"
              type="search"
              placeHolder="Search"
              value={this.state.searchText}
              onChange={this.onChangeSearch}
              canErase
            />
          </div>
          <div className="wrapper">
            {places.map((place, index) => (
              <div
                key={index}
                className={
                  selectedItem && selectedItem.id === place.id
                    ? "item active"
                    : "item"
                }
                onClick={() => this.onSelect(place)}
              >
                {place.name}
              </div>
            ))}
          </div>
        </div>
        {selectedItem ? (
          <div className="content">
            <PlaceDescription item={selectedItem} onClose={this.onClose} />
          </div>
        ) : (
          <div className="content empty">No selected place</div>
        )}
        {this.state.modalOpen && (
          <Modal
            title="New place"
            onClose={this.onCloseEdit}
            open={this.state.modalOpen}
          >
            <div className="placeModal">
              <div className="form">
                <div className="field">
                  <Input
                    id="name"
                    placeHolder="Name"
                    onChange={this.onChangeName}
                    canErase
                    required
                  />
                </div>
              </div>
              <div className="actions">
                <Button
                  disabled={this.state.form.name.valid ? false : true}
                  onClick={this.onSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
const StateToProps = (state) => {
  return state;
};
const DispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(StateToProps, DispatchToProps)(PlacesLayout);

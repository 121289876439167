const initialState = {
  places: [],
};
function placeReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "UPDATE_PLACES":
      nextState = {
        ...state,
        places: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default placeReducer;
